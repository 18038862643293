import React, { useEffect, useState } from "react"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"
import StaticWeb from "../svg/static-website-svg"
import Server from "../svg/server-website-svg"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const [imgWidth, setImgWidth] = useState("")
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }

  useEffect(() => {
    setImgWidth(document.getElementById("svg-wrapper").offsetWidth - 32 + "px")
  }, [])
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | Static websites"
        description="Static websites: descrição do funcionamento"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <p className=" text-3xl">Static websites</p>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="bg-white p-4 rounded-3xl" id="svg-wrapper">
                <div className="  w-fit mx-auto">
                  <StaticWeb width={imgWidth} />
                </div>
              </div>
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Implantamos nossos sistemas através de um CDN (Content
                  Delivery Network) que é um ambiente isolado. Ele pode receber
                  dados dos servidores, mas não pode enviar nenhum dado de volta
                  aos servidores. Isso melhora a segurança.
                </p>
                <p>&nbsp;</p>
                <p>
                  Na verdade o servidor não está em funcionamento o tempo todo,
                  apenas quando alguma atualização é necessária. Então, o
                  servidor inicia, processa as atualizações, gera um novo lote
                  de arquivos otimizados (html, css e javascript), envia esses
                  arquivos para o CDN e desliga. Isso melhora a eficiência
                  energética e diminui a pegada de CO2. Isso também reduz os
                  custos.
                </p>
                <p>&nbsp;</p>
                <p>
                  As atualizações no website podem ser realizadas por meio de
                  programação ou por alteração no banco de dados. Para alterar o
                  banco de dados há um CMS (Content Management System) que
                  realiza operações CRUD (create, update and delete).
                </p>
                <p>&nbsp;</p>
                <p>
                  Um CDN pode responder a centenas de milhares de solicitações
                  por segundo. Assim, mesmo em picos de alta utilização, ninguém
                  fica de fora. Isso é escalabilidade.
                </p>
                <p>&nbsp;</p>
                <p>
                  Um CDN com arquivos otimizados atende as requisições muito
                  mais rápido que um servidor. Isso melhora a experiência do
                  usuário e a classificação de SEO (Search Engine Optimization).
                </p>
                <p>&nbsp;</p>
                <p>Por que isso é diferente?</p>
              </div>
            </div>
          </div>
          <div className="h-20" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <p className=" text-3xl">Websites baseados em servidor</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Estes são os websites desnvolvidos com sistemas como WordPress
                  ou Drupal CMS.
                </p>
                <p>&nbsp;</p>
                <p>Nós não trabalhamos mais com esses sistemas.</p>
              </div>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <div className="bg-white p-4 rounded-3xl" id="svg-wrapper">
                <div className="  w-fit mx-auto">
                  <Server width={imgWidth} />
                </div>
              </div>
              <div className="h-12" />
              <div className="text-left">
                <p>
                  Websites baseados em servidor precisam de potência de
                  processamento para cada operação.
                </p>
                <p>&nbsp;</p>
                <p>
                  Como o CMS (Sistema de Gerenciamento de Conteúdo) está
                  integrado no mesmo servidor, o sistema é mais suscetível a
                  ataques.
                </p>
                <p>&nbsp;</p>
                <p>
                  As atualizações são gerenciadas pelo CMS, que realiza
                  operações CRUD (criar, atualizar e excluir) e imediatamente
                  são disponibilizadas na base de dados.
                </p>
                <p>&nbsp;</p>
                <p>
                  Para cada solicitação que um usuário realiza, o sistema
                  precisa acessar as informações no banco de dados e preparar,
                  na hora, o conjunto de arquivos (html, css e javascript) para
                  enviar ao usuário. Isso leva mais tempo, utiliza mais potência
                  de processamento e consome mais energia elétrica. Em termos de
                  dinheiro é mais caro.
                </p>
                <p>&nbsp;</p>
                <p>
                  Outra desvantagem dos websites baseados em servidor é que há
                  um limite de capacidade de processamento. Se muitos usuários
                  tentarem acessar o site ao mesmo tempo, o tempo de resposta
                  diminui e o sistema pode até ficar inoperante.
                </p>
                <p>&nbsp;</p>
                <p>
                  Existem algumas estratégias para superar esses problemas, como
                  armazenar os arquivos processados em uma área de cache ou
                  lançar automaticamente mais servidores quando a potência de
                  processamento do primeiro ultrapassar um determinado limite.
                  Mas nunca será tão rápido, econômico ou seguro quanto um
                  website estático.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
